import React from 'react';
import { Helmet } from 'react-helmet';

const IndexPage: React.FC = () => {

  return <div>
    <Helmet>
      <title>하이소사이어티 이용약관 (2019.01.20 시행)</title>
      <meta name="viewport" content="width=device-width, user-scalable=no" />
    </Helmet>
    <div style={{ padding: 20 }}>
      <h2>개인정보취급방침</h2>
      <p>(주)누카(이하 "회사"라 함)는 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에
	        의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 회사의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.</p>
      <p>1. 수집하는 이용자의 개인정보</p>
      <p>2. 개인정보의 수집 및 이용목적</p>
      <p>3. 개인정보를 수집하는 방법</p>
      <p>4. 개인정보의 취급위탁</p>
      <p>5. 개인정보의 보유 및 이용기간</p>
      <p>6. 개인정보 파기절차 및 방법</p>
      <p>7. 이용자 개인정보 정확성을 위한 내용</p>
      <p>8. 이용자의 개인정보안전을 위해 취해질 수 있는 서비스 일시 중단조치</p>
      <p>9. 제 3 자와의 정보공유 및 제공 관련 내용</p>
      <p>10. 이용자의 개인정보 비밀유지를 위한 내용</p>
      <p>11. 이용자가 자신의 개인정보를 보호하기 위해 알아야 할 사항</p>
      <p>12. 인지 못한 이용자의 개인정보 및 기타 불만사항에 관한 처리</p>
      <p>13. 개인정보 취급자의 제한에 관한 내용</p>
      <p>14. 이용자 및 법정대리인의 권리와 그 행사방법</p>
      <p>15. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</p>
      <p>16. 개인정보관리책임팀</p>
      <p>17. 고지의 의무</p>
      <br />
      <h3>1. 수집하는 이용자의 개인정보</h3>
      <p>가. 수집하는 개인정보의 항목</p>
      <p>- 필수항목: 성명, 휴대전화번호, 생년월일, ID(이메일계정), 사용 통신사명, 닉네임, 접속 기기정보 (ADID 및 IDFA 포함), 접속 기기의 IP Address, 키, 종교, 프로필 사진</p>
      <p>- 선택항목: 기타 프로필 작성과 서비스 이용을 위한 정보(직업, 거주지역, 소속(졸업) 학교 또는 직장, 직장 관련 증빙서류, 체형, 혈액형, 성격, 재산, 재산 관련 증빙서류, 차, 차 관련
      증빙서류, 연소득(연봉), 연소득(연봉) 관련 증빙서류, 지인 매칭 회피를 위한 주소록 정보, 기타 매칭 서비스를 이용하기 위해 이용자가 직접 입력하는 기타 프로필 정보 등)는 정보주체가 결정하여
	        입력합니다.</p>
      <p>또한 아래의 항목들에 대해서도 안정된 서비스 제공을 위해 추가로 수집할 수 있습니다.</p>
      <p>1) 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록</p>
      <p>2) 본인인증 또는 수동 무통장입금 신청시 등: 이용 이동통신사, 계좌번호 등</p>
      <p>3) 신용카드 결제시: 카드사명, 카드번호 등</p>
      <p>4) 휴대폰 소액 결제시: 이동전화번호, 통신사, 결제승인번호 등</p>
      <p>5) 무통장 또는 계좌이체 결제시: 은행명, 계좌번호 등</p>
      <p>6) 지인 매칭 회피 기능 이용시 접속 기기의 주소록 정보 일부 (지인매칭 회피를 선택한 해당 연락처의 성명 및 휴대폰 번호)</p>
      <p>나. 개인정보 수집방법</p>
      <p>회사는 다음과 같은 방법으로 개인정보를 수집하고 있습니다.</p>
      <p>- 웹사이트, 모바일 웹/앱, 서면양식, 팩스, 전화, 상담 게시판, 이메일, 이벤트 응모</p>
      <p>- 협력회사로부터 공동 제휴 및 협력을 통한 정보 수집</p>
      <p>- 생성정보 수집 툴을 통한 정보 수집</p>
      <br />
      <h3>2. 개인정보의 수집 및 이용 목적</h3>
      <p>가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산에 활용합니다.</p>
      <p>-컨텐츠 제공, 특정 맞춤 서비스 제공, 본인인증, 컨텐츠 구매 및 요금 결제, 요금추심</p>
      <p>나. 이용자 관리를 위해 일부 이용자 정보를 활용합니다</p>
      <p>- 서비스 이용 신청에 따른 본인확인, 개인식별, 불량이용자(이용약관을 위반하거나 성실히 수행하지 않은 이용자)의 부정 이용방지와 비인가 사용방지, 서비스 이용 신청 횟수 제한, 분쟁 조정을 위한
	        기록보존, 불만처리 등 민원처리, 고지사항 전달</p>
      <p>다. 신규 서비스 개발 및 마케팅, 광고에 활용합니다</p>
      <p>-신규 서비스 개발 및 인증 서비스, 맞춤서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 이용자의 서비스 이용에 대한
	        통계, 서비스의 유효성 확인</p>
      <br />
      <h3>3. 개인정보를 수집하는 방법</h3>
      <p>모든 이용자가 회사로부터 서비스를 제공받기 위해서는 이용자의 개인정보가 필요하며 개인정보는 서비스 이용 신청 시 신청양식에 신청자의 동의를 통해 수집됩니다.</p>
      <br />
      <h3>4. 개인정보의 취급위탁</h3>
      <p>회사는 서비스 향상을 위해서 귀하의 개인정보를 외부에 위탁하여 처리할 수 있습니다.</p>
      <p>가. 개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 귀하에게 고지하겠습니다.</p>
      <p>나. 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해
	        계약내용을 서면 또는 전자적으로 보관하겠습니다.</p>
      <p>[수탁업체: 나이스 평가정보(주)]</p>
      <p>- 위탁 업무 내용: 휴대전화를 통한 본인인증</p>
      <p>[수탁업체: Amazon.com, Inc (Amazon Web Service)]</p>
      <p>- 서버 위치: 대한민국, 서울</p>
      <p>- 위탁 업무 내용: 안정적인 서버 및 서비스 운영, 데이터베이스 및 각종 정보 등의 스토리지 저장 등</p>
      <p>[수탁업체: Google.com, Inc (Firebase)]</p>
      <p>- 서버 위치: 일본, 도쿄</p>
      <p>- 위탁 업무 내용: 안정적인 서비 및 서비스 운영, 데이터베이스 및 각종 정보 등의 스토리지 저장 등</p>
      <br />
      <h3>5. 개인정보의 보유 및 이용기간</h3>
      <p>회원 탈퇴, 개인정보의 수집 및 이용목적이 달성된 경우 회원의 개인정보를 지체 없이 파기합니다. 단, 아래 각 사유 및 기간에 한하여 예외적으로 회원의 개인정보를 보유합니다.</p>
      <p>1) 회사 내부 정책에 의한 경우</p>
      <p>불량이용자의 재가입 방지, 부정이용 방지, 기타 민원 및 질의응답 대응</p>
      <p>-보유기간:</p>
      <p>(일반 회원의 탈퇴 후 재가입 방지를 위한 경우) 탈퇴 후 약 7일간</p>
      <p>(부정이용 및 이용규정 위반 대응을 위한 경우-불량이용자의 재가입 방지 포함) 해당 부정이용 및 이용규정 위반 대응 사항에 대한 분쟁 해결시까지로 하며, 분쟁 해결이 원만하지 않을 경우 이와 관련하여
	        관련 법률의 분쟁조정 절차에 따르도록 합니다.</p>
      <p>(민원 또는 서비스 질의응답에 대한 경우) 해당 민원 또는 질의응답 해결시까지</p>
      <p>-보유정보: 이메일주소 (ID), 휴대전화번호, 실명, 닉네임, 중복가입 또는 부정가입 방지 목적을 위한 식별 정보 (이용자 사진, 계정정보, 이용자 기기 정보 등), 중복가입 확인정보, 가입일,
	        탈퇴일, 승인일, 해당 민원/질의응답 내용 또는 부정/위반 이용 행위 내용 기록</p>
      <p>2) 회원이 직접 개인정보의 보존을 요청한 경우 또는 회사가 개별적으로 회원의 동의를 얻은 경우</p>
      <p>-보유기간 및 보유정보: 회원의 요청 또는 동의를 얻은 항목/기간에 한하여 해당 기간 동안 보유</p>
      <p>3) 법령에 의거, 이용자의 동의 없이 보존할 것을 정한 경우</p>
      <p>‘전자상거래 등에서의 소비자보호에 관한 법률’에 의하여 아래의 명시 기간 동안 보관관리 합니다.</p>
      <p>가. 계약, 청약철회, 이용자 서비스 이용 내역 등의 거래에 관한 기록: 5년</p>
      <p>(위 '가'항에 해당하는 기록의 경우 이용자 파악을 위한 식별자료는 최소한의 정보인 이메일주소(ID) 및 휴대폰 번호에 한해 보관하며 기타 개인정보는 '마'항 등에 따라 탈퇴 후 7일 내 전부
	        파기됩니다.)</p>
      <p>나.대금결제 및 재화 등의 공급에 관한 기록 : 5년</p>
      <p>다.소비자의 불만 또는 분쟁처리에 관한 기록 : 5년</p>
      <p>(위 '다'항에 해당하는 기록의 경우 응답 및 처리를 위한 작성자 정보 파악의 식별자료는 최소한의 정보인 이메일주소(ID) 및 휴대폰 번호에 한해 보관하며 기타 개인정보는 '마'항 등에 따라 탈퇴 후
	        7일내 전부 파기됩니다.)</p>
      <p>라.사이트 방문에 관한 기록 : 3개월</p>
      <p>마. 위 '가'-'라'항에 해당하지 않는 가입 시 기재한 모든 개인정보 : 탈퇴 신청 후 7일</p>
      <p>(기타 모든 개인정보는 '마'항에 따라 탈퇴 후 7일 내 모두 파기합니다.)</p>
      <p>4) 개인정보 유효기간제의 적용</p>
      <p>회사는 정보통신망법 관련 조항에 따라 2014년 8월 18일 이전 가입한 회원이 1년 (이하 “개인정보유효기간”이라고 합니다.) 동안 서비스를 이용한 사실 또는 활동한 기록이 없는 경우, 개인정보의
      안전한 보호 및 피해 발생을 방지하기 위해 ‘정보통신망 이용촉진 및 정보보호등에 관한 법률 제29조’에 근거하여 이용자에게 사전통지하고 개인정보를 별도로 분리하여 저장 관리합니다. 회사는 유효기간이
      도래하기 30일 전까지 이메일, 문자메시지, 휴대전화 등 회원이 입력한 연락수단을 통하여 사전통지합니다. 다만, 개인정보유효기간이 도래한 회원의 경우에도 ① 회원의 별도 유효기간 연장의 요청, 유효기간
      갱신의 요청 등 개별 동의가 있는 경우 ② 포인트 또는 유료 아이템 등이 남아 있는 경우 ③ 위 3.항 소정의 타 법령에서 별도로 보존 기간을 정하는 경우에는 관련 법령이 정한 기간 동안 보존 후
	        조치합니다. 개인정보유효기간제에 의해 별도 분리된 경우라도 회원이 서비스 재이용을 원하실 경우에는, 본인확인 절차를 거쳐 서비스를 재이용하실 수 있습니다.</p>
      <br />
      <h3>6. 개인정보 파기절차 및 방법</h3>
      <p>회사는 개인정보 보유기간의 경과 혹은 개인정보의 수집 및 이용목적의 달성 등 개인정보가 불필요하게 되었을 때에는 해당 개인정보를 지체 없이 파기합니다.</p>
      <p>회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</p>
      <p>가. 파기절차</p>
      <p>- 이용자가 서비스 신청 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 잠금장치가 있는 서류보관함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유
	        및 이용기간 참조)일정 기간 저장된 후 파기됩니다.</p>
      <p>- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.</p>
      <p>나. 파기방법</p>
      <p>- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
      <p>- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
      <br />
      <h3>7. 이용자 개인정보 정확성을 위한 내용</h3>
      <p>이용자의 부정확한 개인정보로 인하여 사용상의 불편을 줄 수 있으므로 개인정보 관리자가 판단하기에 확연히 부정확한 개인정보를 기입한 경우에는 정확하지 않은 개인정보를 파기할 수 있습니다.</p>
      <br />
      <h3>8. 이용자의 개인정보안전을 위해 취해질 수 있는 서비스 일시 중단조치</h3>
      <p>회사는 이용자의 안전한 서비스 이용을 위해서 최선을 다하고 있습니다. 그러나 원하지 않는 방법에 의하여 회사의 서비스가 훼손을 당하는 경우에는 이용자들의 개인정보 보호를 위하여, 문제가 완전하게 해결될
	        때까지 이용자의 개인정보를 이용한 서비스를 일시 중단 할 수도 있습니다.</p>
      <br />
      <h3>9. 제 3 자와의 정보공유 및 제공 관련</h3>
      <p>회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제24조의2(개인정보의 제공 동의 등)에 따라 이용자의 동의가 있거나 법률에 특별한 규정이 있는 경우를 제외하고 개인정보를 고지 또는 명시한 범위를
	        초과하여 이용하거나 제3자에게 제공하지 않습니다.</p>
      <p>또한 개인정보보호법 제59조(금지행위)에 따라 회사의 서비스 제공을 위하여 개인정보를 취급하거나 취급하였던 자는 다음 각호의 행위를 하지 않습니다.</p>
      <p>가. 거짓이나 그 밖의 부정한 수단이나 방법으로 개인정보를 취득하거나 처리에 관한 동의를 받는 행위</p>
      <p>나. 업무상 알게 된 개인정보를 누설하거나 권한 없이 다른 사람이 이용하도록 제공하는 행위</p>
      <p>다. 정당한 권한 없이 또는 허용된 권한을 초과하여 다른 사람의 개인정보를 훼손, 멸실, 변경, 위조 또는 유출하는 행위</p>
      <br />
      <h3>10. 이용자의 개인정보 비밀유지를 위한 내용</h3>
      <p>회사는 이용자의 개인정보의 비밀을 유지하기 위하여 제3자에게는 이용자의 동의 없이 개인정보를 유출하지 않습니다. 또한 이용자가 동의를 하였다 하더라도, 제3자를 통하여 재유출이 될 확률이 있는 자에게는
      이용자의 개인정보를 유출하지 않습니다. 회사는 각종 정부기관의 이용자 개인정보의 일방적 제공 요구에 대하여는 이용자의 개인정보를 제공하지 않습니다. 법령에 따른 정부기관이 법령에 따른 공식 절차를
      완벽하게 거쳐 자료를 요구하는 경우에 한하여 이용자의 개인정보를 제공합니다. 회사는 이용자의 개인정보를 회사가 정한 기본서비스 및 기타의 서비스 활동 이외에는 이용하지 않습니다. 위의 활동에 따라
	        이용자의 정보가 필요할 시에는 별도의 양식을 통한 수집 및 동의의 절차를 거쳐서 이용자의 개인정보를 이용합니다.</p>
      <br />
      <h3>11. 이용자가 자신의 개인정보를 보호하기 위해 알아야 할 사항</h3>
      <p>PC방 등 외부 장소에서 '하이소사이어티'를 이용하실 경우 해킹 프로그램 기타 유해 프로그램이 없는지 유의하여 이용하시기 바랍니다. 회사는 개인정보보호에 최선을 다하지만 사용자 개인의 실수나 인터넷 상의
	        문제로 인한 일들에 대해서는 책임을 지지 않습니다.</p>
      <br />
      <h3>12. 인지 못한 이용자의 개인정보 및 기타의 불만사항에 관한 처리</h3>
      <p>회사가 인지하지 못하고 있는 이용자의 개인정보 이용 및 기타의 불만사항에 관하여 이용자 불만처리를 전담하는 관리자를 배정하여 지속적이고, 신속하게 이용자의 불만사항을 처리하고, 처리한 결과에 대하여 즉시
	        응대합니다.</p>
      <br />
      <h3>13. 개인정보 취급자의 제한에 관한 내용</h3>
      <p>회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 수시 교육을 통하여 개인정보취급방침의 준수를 강조하고 있습니다.</p>
      <br />
      <h3>14. 이용자 및 법정대리인의 권리와 그 행사방법</h3>
      <p>- 이용자 및 법정 대리인은 언제든지 신청되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 이용해지를 요청할 수 있습니다.</p>
      <p>- 이용자의 개인정보 조회, 수정을 위해서는 직접 모바일 앱 또는 웹을 통해 수정하시거나, 고객센터 또는 담당자에게 이메일 등을 통해 신청을, 이용해지(동의철회)를 위해서는 회사가 정하는 탈퇴 신청
	        양식에 따른 고객센터에 이메일 문의 또는 모바일 앱 상 온라인 신청을 통하여 계약 해지 및 탈퇴가 가능합니다.</p>
      <p>- 혹은 고객센터나 개인정보책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.</p>
      <p>- 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니템. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를
	        제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</p>
      <p>- 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보를 개인정보취급방침 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수
	        없도록 처리하고 있습니다.</p>
      <br />
      <h3>15. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h3>
      <p>회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가
      이용자의
	        컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</p>
      <p>가. 쿠키의 사용 목적</p>
      <p>이용자들의 편리한 기능을 제공하기 위하여 활용되며 유해한 목적으로는 활용되지 않습니다.</p>
      <p>나. 쿠키의 설치/운영 및 거부</p>
      <p>- 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의
      저장을
	        거부할 수도 있습니다.</p>
      <p>- 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
	    </p>
      <p>- 설정방법 예(인터넷 익스플로어의 경우): 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보</p>
      <p>- 다만, 쿠키의 저장을 거부할 경우에는 이용에 어려움이 있을 수 있습니다.</p>
      <br />
      <h3>16. 개인정보관리책임자팀</h3>
      <p>귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보 관리담당자 혹은 담당부서로 신고하실 수 있습니다.</p>
      <p>회사는 이용자들의 신고사항에 대해 신속하고 충분한 답변을 드릴 것입니다.</p>
      <p>메일 : support@nuca.io</p>
      <br />
    </div>
  </div>
}

export default IndexPage